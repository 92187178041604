import React, { FC } from 'react';

interface IProps {
  doc: File;
}

const DocPreview: FC<IProps> = ({ doc }) => {
  return (
    <iframe
      title='doc'
      src={URL.createObjectURL(doc) + '#toolbar=0'}
      className='w-full h-full pt-[22px] px-[22px] bg-transparent border-none outline-none'
    ></iframe>
  );
};

export default React.memo(DocPreview);
