import React, { FC } from 'react';

import DialogLayout from '../DialogLayout';
import Typography from '../../components/Typography/Typography';
import ASSETS from '../../assets';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Profile: FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <DialogLayout open={open}>
      <div className='w-full max-w-[536px] p-[50px] bg-white rounded-[15px] relative'>
        <div
          className='absolute top-[25px] right-[25px] rounded-full size-[30px] bg-[#0000000D] cursor-pointer grid place-items-center'
          onClick={handleClose}
        >
          <img src={ASSETS.cross} alt='' />
        </div>
        <Typography variant='h2' className='font-bold'>
          Profile
        </Typography>
        <Typography variant='body1' color='grey' className='mt-[5px]'>
          Edit your name and copy your wallet address. To make a change to your
          email address, please contact your organization’s admin.
        </Typography>
        <div className='border border-k-border rounded-lg p-6 mt-[25px] flex flex-col gap-6'>
          <div>
            <Typography variant='body1' color='grey'>
              Wallet Address
            </Typography>
            <div className='flex items-end gap-[30px] mt-2'>
              <div className='flex-grow'>
                <Typography className='break-words max-w-[300px]'>
                  0xdf2C3dacE6F31e650FD03B8Ff72beE82Cb1C199A
                </Typography>
              </div>
              <div className='flex items-center gap-2 cursor-pointer'>
                <Typography variant='body1' color='blue'>
                  Copy
                </Typography>
                <img src={ASSETS.copy_icon_blue} className='w-[13px]' alt='' />
              </div>
            </div>
          </div>
          <TextField label='First Name' />
          <TextField label='Last Name' />
          <TextField label='Email' value='hunter.jackson@kpmg.com' readOnly />
          <Button label='Save Changes' variant='primary' className='w-full' />
        </div>
      </div>
    </DialogLayout>
  );
};

export default Profile;
