import { useEffect, useState } from 'react';

import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Typography from '../../components/Typography/Typography';
import { twMerge } from 'tailwind-merge';
import Button from '../../components/Button/Button';
import { useMagic } from '../../contexts/MagicContext';
import { ethers } from 'ethers';
import { IUser } from '../../types/interfaces';
import { onBoarding } from '../../http';
import CONSTANTS from '../../contants';
import { ClipLoader } from 'react-spinners';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { magic } = useMagic();

  const { tokenPromise, email } = useSelector((state: RootState) => state.auth);

  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResencOtp = async () => {
    try {
      if (tokenPromise) {
        setLoading(true);
        try {
          tokenPromise.emit('cancel');
        } catch (error) {
          setLoading(false);
        }
        const token: any = magic?.auth.loginWithEmailOTP({
          email: email,
          showUI: false,
        });

        token
          .on('email-otp-sent', () => {
            console.log('Email OTP sent');
            dispatch.auth.setTokenPromise(token);
          })
          .on('invalid-email-otp', () => {
            console.log('invalid-email-otp-error ---------');
            setLoading(false);
            // setErrors('Invalid OTP');
          }) // @ts-ignore
          ?.on('done', async (result: string) => {
            console.log('otp-done ---------');
            console.log('isLoggedIn ---------', await magic?.user.isLoggedIn());
            const metadata = await magic?.user.getMetadata();

            if (!tokenPromise || !metadata?.publicAddress) {
              // throw new Error("Magic login failed");
              return null;
            }

            localStorage.setItem('user', JSON.stringify(metadata));
            // setUser(metadata);
            // // }

            localStorage.setItem(
              'access_token',
              await magic?.user.getIdToken()!
            );

            const accessToken = await magic?.user.getIdToken()!;
            const provider = new ethers.BrowserProvider(
              magic?.rpcProvider as any
            );
            const signer = await provider.getSigner();
            const address = await signer.getAddress();

            const user: IUser = {
              walletAddress: address,
              email: metadata.email!,
              firstName: null,
              lastName: null,
            };

            const { data } = await onBoarding(accessToken);
            localStorage.setItem(CONSTANTS.token, data.access_token);
            dispatch.auth.setUser(user);
            setLoading(false);
            navigate('/documents');
          })
          // @ts-ignore
          ?.on('error', reason => {
            console.log('otp-error ---------');
            // setErrors('Error');
          })
          ?.on('settled', () => {
            console.log('otp-settled ---------');
            // is called when the Promise either resolves or rejects
          });
      }
    } catch (err: any) {
    } finally {
    }
  };

  const handleVerifyOTP = async () => {
    try {
      if (tokenPromise) {
        tokenPromise.emit('verify-email-otp', otp);
        setLoading(true);
        tokenPromise
          .on('invalid-email-otp', () => {
            console.log('invalid-email-otp-error ---------');
            setError('Invalid OTP');
            setLoading(false);
          })
          // @ts-ignore
          ?.on('done', async (result: string) => {
            console.log('otp-done ---------');
            console.log('isLoggedIn ---------', await magic?.user.isLoggedIn());
            const metadata = await magic?.user.getMetadata();

            if (!tokenPromise || !metadata?.publicAddress) {
              // throw new Error("Magic login failed");
              return null;
            }

            localStorage.setItem('user', JSON.stringify(metadata));
            // setUser(metadata);
            // // }

            localStorage.setItem(
              'access_token',
              await magic?.user.getIdToken()!
            );

            const accessToken = await magic?.user.getIdToken()!;
            const provider = new ethers.BrowserProvider(
              magic?.rpcProvider as any
            );
            const signer = await provider.getSigner();
            const address = await signer.getAddress();

            const user: IUser = {
              walletAddress: address,
              email: metadata.email!,
              firstName: null,
              lastName: null,
            };

            const { data } = await onBoarding(accessToken);
            localStorage.setItem(CONSTANTS.token, data.access_token);
            dispatch.auth.setUser(user);
            setLoading(false);
            navigate('/documents');
          })
          // @ts-ignore
          ?.on('error', reason => {
            console.log('otp-error ---------');
            // setErrors('Error');
          })
          ?.on('settled', () => {
            console.log('otp-settled ---------');
            // is called when the Promise either resolves or rejects
          });
      }
    } catch (err: any) {
    } finally {
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleVerifyOTP();
    }
    setError('');
    // eslint-disable-next-line
  }, [otp]);

  return (
    <div className='flex flex-col items-center py-[90px]'>
      <Typography variant='h1' className='title'>
        Enter 6-digit OTP
      </Typography>
      <Typography variant='h5' color='grey' className='mt-[22px]'>
        Check your email for the one-time password sent to:
      </Typography>
      <Typography variant='h5' color='blue'>
        {email}
      </Typography>
      <div className='w-[380px] mx-auto my-11'>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span className='w-[10px]'></span>}
          renderInput={props => (
            <input
              {...props}
              className={twMerge(
                'border border-k-grey-light rounded-[12px]  w-full flex-grow h-20 text-[44px] font-[264] focus:border-k-blue focus:border-[1.5px] outline-none',
                otp.length === 6 && 'border-k-light-blue border-[1.5px]',
                error && '!border-k-error border-[1.5px]'
              )}
            />
          )}
        />
      </div>
      {loading && (
        <div className='flex items-center justify-center my-5'>
          <ClipLoader size={30} color='#030A74' />
        </div>
      )}
      <Typography color='grey'>Code will expire in 10 minutes.</Typography>
      <Button
        label='Resend Code'
        className='mt-5'
        onClick={handleResencOtp}
        disabled={loading}
      />
    </div>
  );
};

export default VerifyEmail;
