import React, { useState, useRef } from 'react';

import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import MyAttesations from './components/MyAttesations';
import AllAttesations from './components/AllAttesations';
import KPMGScan from './components/KPMGScan';

import ASSETS from '../../assets';

type ITEMS = 'my-attesations' | 'all-attesations' | 'kpmg-scan';

const Documents = () => {
  const slider = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [active, setActive] = useState<ITEMS>('my-attesations');

  const handleChangeActive = (val: ITEMS) => {
    setActive(val);
    if (slider.current) {
      if (val === 'my-attesations') {
        slider.current.style.left = '3px';
      } else if (val === 'kpmg-scan') {
        slider.current.style.left = 'calc(66.66% - 3px)';
      } else {
        slider.current.style.left = 'calc(33.33%)';
      }
    }
  };

  const handleAddDocument = () => {
    navigate('/add-document');
  };

  return (
    <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] flex flex-col mb-[104px]'>
      <div className='flex items-center justify-between'>
        <div>
          <Typography variant='h2' className='font-light leading-[40px]'>
            Attestations
          </Typography>
          <Typography variant='h6' color='grey'>
            Click on any document below to view details, add revisions, and
            share the EAS hash.
          </Typography>
        </div>
        <Button
          label='Add New'
          variant='primary'
          leftIcon={ASSETS.add}
          onClick={handleAddDocument}
        />
      </div>
      <div className='mt-10'>
        <div className='flex items-center gap-[15px] p-[3px]'>
          <div className='flex flex-grow h-[52px] bg-k-grey-bg rounded-3xl border border-k-border relative'>
            <div
              className='flex-1 flex items-center justify-center cursor-pointer'
              onClick={() => handleChangeActive('my-attesations')}
            >
              <Typography
                variant='h6'
                color={active === 'my-attesations' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all',
                  active === 'my-attesations' ? 'font-semibold' : 'font-medium'
                )}
              >
                My Attestations (0)
              </Typography>
            </div>
            <div
              className='flex-1 flex items-center justify-center  cursor-pointer'
              onClick={() => handleChangeActive('all-attesations')}
            >
              <Typography
                variant='h6'
                color={active === 'all-attesations' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all',
                  active === 'all-attesations' ? 'font-semibold' : 'font-medium'
                )}
              >
                All Attestations (28)
              </Typography>
            </div>
            <div
              className='flex-1 flex items-center justify-center  cursor-pointer'
              onClick={() => handleChangeActive('kpmg-scan')}
            >
              <Typography
                variant='h6'
                color={active === 'kpmg-scan' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all',
                  active === 'kpmg-scan' ? 'font-semibold' : 'font-medium'
                )}
              >
                KPMG Scan
              </Typography>
            </div>
            <div
              ref={slider}
              className='slider w-[calc(33.33%)] absolute left-[3px] top-[3px] h-[calc(100%_-_6px)] rounded-3xl bg-white border border-k-border transition-all duration-300 shadow-tab_shadow'
            ></div>
          </div>
          <div className='min-w-[331px] h-[52px] bg-k-grey-bg rounded-3xl border border-k-border flex items-center overflow-hidden pr-4'>
            <input
              type='text'
              className='flex-grow h-full outline-none border-none bg-transparent px-4 text-base placeholder:text-k-grey-text'
              placeholder='Attestation, UID, schema or address'
            />
            <img src={ASSETS.search} alt='search' />
          </div>
        </div>
      </div>
      <div className='mt-5'>
        {active === 'kpmg-scan' && <KPMGScan />}
        {active === 'all-attesations' && <AllAttesations />}
      </div>

      {active === 'my-attesations' && (
        <div className='flex-grow h-1'>
          <MyAttesations />
        </div>
      )}
    </div>
  );
};

export default Documents;
