import { OAuthExtension } from '@magic-ext/oauth';
import { Magic as MagicBase } from 'magic-sdk';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type Magic = MagicBase<OAuthExtension[]>;

type MagicContextType = {
  magic: Magic | null;
};

const MagicContext = createContext<MagicContextType>({
  magic: null,
});

export const useMagic = () => useContext(MagicContext);

const MagicProvider = ({ children }: { children: ReactNode }) => {
  const [magic, setMagic] = useState<Magic | null>(null);

  useEffect(() => {
    if (process.env.REACT_APP_MAGIC_API_KEY) {
      const _magic = new MagicBase(
        process.env.REACT_APP_MAGIC_API_KEY as string,
        {
          network: {
            rpcUrl:
              'https://eth-sepolia.g.alchemy.com/v2/h9RWCx3Aq_mCeuDth1kGZB3MJixkoQhC',
            chainId: 8453,
          },
          extensions: [new OAuthExtension()],
        }
      );

      // _magic.user.revealPrivateKey();

      setMagic(_magic);
      //   setWeb3(new Web3((_magic as any).rpcProvider));
    }
  }, []);

  const value = useMemo(() => {
    return {
      magic,
    };
  }, [magic]);

  return (
    <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
  );
};

export default MagicProvider;
