import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import DialogLayout from '../DialogLayout';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import Stepper from '../../components/Stepper/Stepper';
import { IStepperConfig } from '../../types/interfaces';
import { sleep } from '../../utils';
import ASSETS from '../../assets';

const CONFIG: IStepperConfig[] = [
  {
    text: 'Saving',
    successText: 'Saved',
    finished: false,
  },
  {
    text: 'Converting',
    successText: 'Converted',
    finished: false,
  },
  {
    text: 'Processing',
    successText: 'Processed',
    finished: false,
  },
  {
    text: 'Creating',
    successText: 'Created',
    finished: false,
  },
];

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  doc: File | null;
  text: string;
}

const ProcessingUpload: React.FC<IProps> = ({
  open,
  setOpen,
  onClose,
  doc,
  text,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { attesationType, doc_hash, text_hash } = useSelector(
    (state: RootState) => state.attesation
  );

  const [active, setActive] = useState(0);
  const [config, setConfig] = useState<IStepperConfig[]>(CONFIG);
  const [title, setTile] = useState('Processing Your Upload');
  const [description, setDescription] = useState('Saving 5%...');

  const [hash, setHash] = useState('');

  const handleContinue = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSignData = () => {
    
  }

  const handleProcessUpload = async () => {
    await sleep(1000);
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[0].finished = true;
      return newConfig;
    });
    setDescription('Converting to docbyte…');
    await sleep(1000);
    setActive(1);
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[1].finished = true;
      return newConfig;
    });
    setDescription('Processing through SHA-256 hash…');
    await sleep(1000);
    setActive(2);
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[2].finished = true;
      return newConfig;
    });
    setDescription('Creating the immutable hash…');
    await sleep(1000);
    setActive(3);
    setTile('Upload Processed');
    setConfig(prev => {
      const newConfig = [...prev];
      newConfig[3].finished = true;
      return newConfig;
    });
    if (attesationType === 'document' && doc) {
      const formData = new FormData();
      formData.append('file', doc);
      dispatch.attesation.handleGenerateDocHash(formData);
    } else {
      dispatch.attesation.handleGenerateTextHash(text);
    }
  };

  useEffect(() => {
    setConfig(CONFIG.map(i => ({ ...i })));
    setActive(0);
    setTile('Processing Your Upload');
    setDescription('Saving 5%...');
    handleProcessUpload();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (doc_hash && attesationType === 'document') {
      setHash(doc_hash);
    }
    if (text_hash && attesationType === 'text') {
      setHash(text_hash);
    }
    // eslint-disable-next-line
  }, [doc_hash, text_hash]);

  return (
    <DialogLayout open={open}>
      <div className='w-full max-w-[536px] bg-white p-[25px] rounded-[15px] max-h-[95vh] overflow-auto aspect-square flex flex-col'>
        <Stepper activeIndex={active} steps={config} />
        <div className='flex-grow flex flex-col justify-between items-center'>
          <div />
          <div className='w-full items-center flex flex-col'>
            <Typography variant='h3' color='blue'>
              {title}
            </Typography>
            {hash ? (
              <div className='w-full max-w-[360px] border border-k-grey-200 rounded-[12px] shadow-auth_box p-[25px] mt-[25px] flex items-center gap-6'>
                <div className='flex-grow'>
                  <Typography variant='h6' color='grey'>
                    {attesationType === 'document' ? 'Doc' : 'Text'} Hash:
                  </Typography>
                  <Typography variant='h6' className='break-words max-w-[22ch]'>
                    {hash}
                  </Typography>
                </div>
                <Button label='Copy' leftIcon={ASSETS.copy_black} />
              </div>
            ) : (
              <Typography
                variant='h6'
                color='grey'
                className='text-center mt-[25px]'
              >
                {description}
              </Typography>
            )}
          </div>
          <div className='mb-10'>
            <Button
              label='Continue with Attestation'
              disabled={!hash}
              variant='primary'
              onClick={handleContinue}
            />
          </div>
        </div>
      </div>
    </DialogLayout>
  );
};

export default ProcessingUpload;
