import React from 'react';
import Typography from '../../../components/Typography/Typography';
import ASSETS from '../../../assets';
import Chip from '../../../components/Chip/Chip';
import Button from '../../../components/Button/Button';

const AllAttesations = () => {
  return (
    <div className='flex flex-col'>
      {Array.from({ length: 10 }, (_, i) => i).map(i => (
        <div
          key={i}
          className='flex items-center justify-between gap-[30px] px-5 pt-6 pb-[30px] border-b border-b-k-border first-of-type:border-t first-of-type:border-t-k-border
          hover:border-none hover:shadow-auth_box'
        >
          <div className='flex items-start gap-5 flex-grow'>
            <div className='size-[50px] min-w-[50px] rounded-lg border border-k-border overflow-hidden relative'>
              <img src={ASSETS.doc} alt='' />
              <img
                src={ASSETS.docx}
                className='absolute right-0 bottom-0'
                alt=''
              />
            </div>
            <div>
              <Typography variant='h5'>Inte Kadat Såväl Som Fanat. </Typography>
              <Typography
                variant='h6'
                color='grey'
                className='line-clamp-2 mt-[10px]'
              >
                Main takeaways passivhus per den kadår. Terara serass vadende
                ornjä självkarantän. Matnationalism hende den antropoism abel.
                Lörem semil plare plarade. Sänade molekylärgastronomi episkop.
                Beheten tesektig, polyskade.
              </Typography>
              <div className='flex items-center gap-2 mt-[18px] flex-wrap'>
                <Chip label='Tag Lorem' />
                <Chip label='Ipsum' />
                <Chip label='Dolor' />
                <Chip label='Amer Sit' />
                <Chip label='Antropoism' />
              </div>
            </div>
          </div>
          <div className='min-w-[195px]'>
            <div className='flex items-center justify-between'>
              <Typography variant='body1' color='grey'>
                Last Modified:
              </Typography>
              <Typography variant='body1' className='font-bold'>
                Aug 5, 2024
              </Typography>
            </div>
            <div className='flex items-center justify-between mt-1'>
              <Typography variant='body1' color='grey'>
                Versions:
              </Typography>
              <Typography variant='body1' className='font-bold'>
                2
              </Typography>
            </div>
            <Button
              label='Verify on EAS'
              className='w-full justify-center mt-[11px]'
              rightIcon={ASSETS.arrow_up}
            />
            <div className='text-center mt-3'>
              <div className='items-center gap-2 inline-flex mx-auto cursor-pointer'>
                <Typography
                  variant='body1'
                  className='text-k-dark-blue font-semibold'
                >
                  Copy UID
                </Typography>
                <img src={ASSETS.copy_icon_blue} alt='' />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllAttesations;
