import React, { FC } from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const variants = cva(
  'text-base font-semibold rounded-3xl justify-center flex items-center gap-2 px-[18px] py-3',
  {
    variants: {
      variant: {
        primary:
          'bg-k-dark-blue !text-white hover:bg-[#0734A9] transition-all disabled:bg-[#E5E5EA] disabled:cursor-not-allowed',
        outlined: 'border border-k-light-blue hover:bg-[#00000005]',
      },
      textColor: {
        white: 'text-white',
        blue: 'text-k-light-blue',
        black: 'text-k-black',
      },
    },
    defaultVariants: {
      variant: 'outlined',
      textColor: 'black',
    },
  }
);

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof variants> {
  label: string;
  leftIcon?: string;
  rightIcon?: string;
}

const Button: FC<ButtonProps> = ({
  variant,
  textColor,
  label,
  className,
  leftIcon,
  rightIcon,
  ...rest
}) => {
  return (
    <button
      className={twMerge(variants({ variant, textColor, className }))}
      {...rest}
    >
      {leftIcon && <img src={leftIcon} alt='' />}
      {label}
      {rightIcon && <img src={rightIcon} alt='' />}
    </button>
  );
};

export default Button;
