import { apiUrl } from '../app.config';
import axios from 'axios';
import CONSTANTS from '../contants';

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(CONSTANTS.token);
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      if (window.location.pathname !== '/sign-in')
        window.location.pathname = '/sign-in';
    }
    return Promise.reject(error);
  }
);

export default api;

export const onBoarding = (token: string) =>
  api.post<{ message: string; access_token: string }>('/v1/user/onboarding', {
    token,
  });

export const createHashWithDoc = (data: FormData) =>
  api.post<{ hash: string }>('/v1/user/uploadDocToCreateHash', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createHashWithText = (text: string) =>
  api.post<{ hash: string }>('/v1/user/uploadTextToCreateHash ', { text });
