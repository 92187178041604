import { useRef, useState, useId, useContext, useEffect } from 'react';

import { EAS, SchemaEncoder } from '@ethereum-attestation-service/eas-sdk';
import { ethers } from 'ethers';

import { twMerge } from 'tailwind-merge';
import { string, object, array, InferType } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useMagic } from '../../contexts/MagicContext';

import TextField from '../../components/TextField/TextField';
import Typography from '../../components/Typography/Typography';
import Textarea from '../../components/Textarea/Textarea';
import TagsField from '../../components/TagsField/TagsField';
import Button from '../../components/Button/Button';
import FilePicker from '../../components/FilePicker/FilePicker';

import ProcessingUpload from '../../dialogs/ProcessingUpload/ProcessingUpload';
import SignatureRequest from '../../dialogs/SignatureRequest/SignatureRequest';
import GeneratingAttesation from '../../dialogs/GeneratingAttesation/GeneratingAttesation';
import Editor from '../../components/Editor/Editor';
import WhatIsAttesation from '../../components/WhatIsAttesation/WhatIsAttesation';

import ASSETS from '../../assets';
import { useFormik } from 'formik';
import { EditorContext } from '../../contexts/EditorContext';
import DocPreview from '../../components/DocPreview/DocPreview';
import { easContractAddress, easUId } from '../../app.config';

const schema = object({
  title: string().required('Title is required'),
  description: string().optional().label('Description'),
  tags: array().of(string()).max(5).optional().label('Tags'),
});

interface IInitialValues extends InferType<typeof schema> {}

const eas = new EAS(easContractAddress!);

const AddDocument = () => {
  const slider = useRef<HTMLDivElement>(null);
  const editor = useContext(EditorContext);

  const { magic } = useMagic();

  const dispatch = useDispatch<Dispatch>();

  const [editorContent, setEditorContent] = useState('');
  const {
    attesationType: active,
    doc_hash,
    text_hash,
  } = useSelector((state: RootState) => state.attesation);

  const { user } = useSelector((state: RootState) => state.auth);

  // test commit

  const formik = useFormik<IInitialValues>({
    initialValues: {
      title: '',
      description: '',
      tags: [],
    },
    validationSchema: schema,
    onSubmit: values => {
      console.log(values);
      handleSaveAndAttesation();
      setEditorContent(JSON.stringify(editor?.getHTML()));
    },
  });

  const [processDoc, setProcessDoc] = useState(false);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [generateAttesation, setGenerateAttesation] = useState(false);
  const [show, setShow] = useState(false);
  const [doc, setDoc] = useState<File | null>(null);

  const picker = useId();

  const handleSaveAndAttesation = () => {
    setProcessDoc(true);
  };

  const handleEncodeData = async () => {
    // try {
    // const provider = new ethers.JsonRpcProvider(magic?.rpcProvider as any);
    const provider = new ethers.BrowserProvider(magic?.rpcProvider as any);
    const signer = await provider.getSigner();
    await eas.connect(signer);
    const delegated = await eas.getDelegated();
    const schemaEncoder = new SchemaEncoder(
      'string attestation_type,string title,string description,string[] tags,bytes32 document_hash,bytes32 text_hash,bytes merkle_root,bytes nullifier_hash,bytes proof,bytes verification_level'
    );
    console.log('encoding', text_hash, doc_hash);

    const _document_hash = ethers.zeroPadBytes(
      ethers.getBytes('0x' + doc_hash),
      32
    );
    const _text_hash = ethers.zeroPadBytes(
      ethers.getBytes('0x' + text_hash),
      32
    );

    console.log(_document_hash, _text_hash);
    const encodedData = schemaEncoder.encodeData([
      { name: 'attestation_type', value: active, type: 'string' },
      { name: 'title', value: formik.values.title, type: 'string' },
      {
        name: 'description',
        value: formik.values.description || '',
        type: 'string',
      },
      { name: 'tags', value: formik.values.tags || [], type: 'string[]' },
      {
        name: 'document_hash',
        value: active === 'document' ? _document_hash : _document_hash,
        type: 'bytes32',
      },
      {
        name: 'text_hash',
        value: active === 'text' ? _text_hash : _text_hash,
        type: 'bytes32',
      },
      { name: 'merkle_root', value: '0x00', type: 'bytes' },
      { name: 'nullifier_hash', value: '0x00', type: 'bytes' },
      { name: 'proof', value: '0x00', type: 'bytes' },
      { name: 'verification_level', value: '0x00', type: 'bytes' },
    ]);

    console.log(encodedData);

    const response = await delegated.signDelegatedAttestation(
      {
        schema: easUId!,
        recipient: user!.walletAddress,
        expirationTime: BigInt(0),
        revocable: true,
        refUID:
          '0x0000000000000000000000000000000000000000000000000000000000000000',
        data: encodedData,
        deadline: BigInt(0),
        value: BigInt(0),
      },
      signer
    );

    console.log('response ====>', response);

    // console.log('response ====>', response);
    // } catch (err: any) {
    //   console.log(err);
    // }
  };

  const handleTagsChange = (tag: string) => {
    const tags = formik.values.tags || [];
    if (tags.includes(tag)) {
      tags.splice(tags.indexOf(tag), 1);
    } else {
      tags.push(tag);
    }
    formik.setFieldValue('tags', tags);
  };

  const handleChangeTab = (val: typeof active) => {
    dispatch.attesation.setAttesationType(val);
    if (slider.current) {
      if (val === 'text') {
        slider.current.style.left = '3px';
      } else {
        slider.current.style.left = 'calc(50%)';
      }
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.length) {
      const file = e.currentTarget.files[0];
      if (file.name.endsWith('.docx')) {
      }
      setDoc(file);
    }
  };

  useEffect(() => {
    console.log(formik.errors);
  }, [formik.errors]);

  return (
    <div className='flex-grow bg-white shadow-auth_box rounded-[15px] p-[50px] flex mb-[32px] gap-[50px] relative'>
      <div className='flex-1 border border-k-border overflow-hidden rounded-[12px] flex flex-col'>
        <div className='bg-white py-[10px] px-4 border-b border-k-border'>
          <div className='flex relative h-[52px] rounded-3xl bg-k-grey-bg border border-k-border'>
            <div
              className='flex-1 flex h-full justify-center items-center cursor-pointer'
              onClick={() => handleChangeTab('text')}
            >
              <Typography
                variant='h6'
                color={active === 'text' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all ',
                  active === 'text' ? 'font-semibold' : 'font-medium'
                )}
              >
                Text
              </Typography>
            </div>
            <div
              className='flex-1 flex h-full justify-center items-center cursor-pointer'
              onClick={() => handleChangeTab('document')}
            >
              <Typography
                variant='h6'
                color={active === 'document' ? 'black' : 'grey'}
                className={twMerge(
                  'z-10 transition-all ',
                  active === 'document' ? 'font-semibold' : 'font-medium'
                )}
              >
                Document
              </Typography>
            </div>
            <div
              ref={slider}
              className='slider w-[calc(50%_-_3px)] absolute left-[3px] top-[3px] h-[calc(100%_-_6px)] rounded-3xl bg-white border border-k-border transition-all duration-300 shadow-tab_shadow'
            ></div>
          </div>
        </div>
        {active === 'document' && (
          <div className={'flex-grow bg-k-grey-200 h-1'}>
            {doc ? (
              <div className='h-full flex flex-col items-center relative'>
                <DocPreview doc={doc} />
                <label htmlFor={picker}>
                  <div className='absolute cursor-pointer bottom-[80px] left-[50%] translate-x-[-50%]'>
                    <Button
                      label='Replace File'
                      variant='primary'
                      leftIcon={ASSETS.upload_files}
                      className='pointer-events-none'
                    />
                  </div>
                </label>
              </div>
            ) : (
              <div
                className='h-full flex flex-col items-center pt-[88px] pb-[45px]'
                onDragEnter={() => setShow(true)}
              >
                <img src={ASSETS.doc_preview} alt='' />
                <div className='mt-auto flex flex-col items-center'>
                  <label htmlFor={picker}>
                    <div className='inline-block cursor-pointer'>
                      <Button
                        leftIcon={ASSETS.upload_files}
                        label='Upload File'
                        variant='primary'
                        role='grid'
                        className='pointer-events-none'
                      />
                    </div>
                  </label>

                  <div className='cursor-pointer' onClick={() => setShow(true)}>
                    <Typography
                      variant='body1'
                      color='grey'
                      className='mt-[35px] cursor-pointer'
                    >
                      Drag and drop file to upload.
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {active === 'text' && (
          <div className='flex-grow bg-k-grey-200 h-1'>
            <Editor />
          </div>
        )}
      </div>
      <div className='flex-1 flex flex-col gap-5'>
        <Typography variant='h2'>Attestation Information</Typography>
        <Typography variant='h6' color='grey'>
          Add metadata to your attestation to make it easier to search for and
          review.
        </Typography>
        <div className='flex flex-col gap-5'>
          <TextField
            label='Attestation Name'
            name='title'
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.title && formik.errors.title
                ? formik.errors.title
                : ''
            }
          />
          <Textarea
            label='Description (optional)'
            name='description'
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // error={
            //   formik.touched.description && formik.errors.description
            //     ? formik.errors.description
            //     : ''
            // }
          />
          <div>
            <TagsField
              label='Tags (optional)'
              tags={(formik.values.tags || []) as string[]}
              onTagChange={v => handleTagsChange(v)}
            />
            <div className='flex items-center justify-between mt-2'>
              <Typography variant='body1' color='grey'>
                Add a comma or hit enter after each tag.
              </Typography>
              <Typography variant='body1' color='grey'>
                {formik.values.tags?.length}/5
              </Typography>
            </div>
          </div>
        </div>
        <div className='mt-auto'>
          {active === 'document' && doc && (
            <div className='flex items-center justify-between border border-k-border rounded-3xl py-[9px] px-[15px] gap-[10px]'>
              <div className='flex items-center gap-[10px]'>
                <img src={ASSETS.doc_icon} alt='' />
                <Typography
                  variant='body1'
                  className='text-k-dark-blue font-bold'
                >
                  {doc?.name}
                </Typography>
              </div>
              <div className='flex items-center gap-[10px]'>
                <Typography variant='body1' color='grey'>
                  ({Math.floor((doc?.size || 0) / 1024)}k)
                </Typography>
                <img
                  src={ASSETS.close_icon}
                  className='ml-[5px] cursor-pointer'
                  alt=''
                  onClick={() => setDoc(null)}
                />
              </div>
            </div>
          )}
          <Button
            label='Save and Begin Attestation'
            variant='primary'
            className='w-full my-5'
            type='submit'
            onClick={() => formik.handleSubmit()}
          />
          <WhatIsAttesation />
        </div>
      </div>
      {show && (
        <div className='absolute w-full h-full left-0 top-0 z-20 p-5'>
          <FilePicker setShow={setShow} setFile={setDoc} />
        </div>
      )}
      <input
        type='file'
        name=''
        id={picker}
        className='hidden'
        accept='application/pdf'
        onChange={handleFileChange}
      />
      {processDoc && (
        <ProcessingUpload
          text={editorContent}
          doc={doc}
          open={processDoc}
          setOpen={setProcessDoc}
          onClose={() => setOpenSignatureModal(true)}
        />
      )}
      {openSignatureModal && (
        <SignatureRequest
          open={openSignatureModal}
          setOpen={setOpenSignatureModal}
          onClose={() => {
            handleEncodeData();
            setGenerateAttesation(true);
          }}
        />
      )}
      {generateAttesation && (
        <GeneratingAttesation
          open={generateAttesation}
          setOpen={setGenerateAttesation}
        />
      )}
    </div>
  );
};

export default AddDocument;
