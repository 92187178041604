import React from 'react';

import { Routes, Route } from 'react-router-dom';

import AuthLayout from '../layouts/AuthLayout/AuthLayout';
import Login from '../pages/Login/Login';
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail';
import Logging from '../pages/Logging/Logging';
import DashboardLayout from '../layouts/DashboardLayout/DashboardLayout';
import Documents from '../pages/Documents/Documents';
import AddDocument from '../pages/AddDocument/AddDocument';
import EditorProvider from '../contexts/EditorContext';

const index = () => {
  return (
    <EditorProvider>
      <Routes>
        <Route path='/' element={<AuthLayout />}>
          <Route path='' index element={<Login />} />
          <Route path='verify-email' element={<VerifyEmail />} />
          <Route path='logging-in' element={<Logging />} />
        </Route>
        <Route path='/' element={<DashboardLayout />}>
          <Route path='/documents' element={<Documents />} />
          <Route path='/add-document' element={<AddDocument />} />
        </Route>
      </Routes>
    </EditorProvider>
  );
};

export default index;
