import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IUser } from '../../types/interfaces';

interface IState {
  isLoggedIn: boolean;
  tokenPromise: any;
  email: string;
  user: IUser | null;
  hashData: string;
  attesationType: 'text' | 'document';
}

export const auth = createModel<RootModel>()({
  state: {
    isLoggedIn: false,
    tokenPromise: null,
    email: '',
    user: null,
    hashData: '',
    attesationType: 'text',
  } as IState,
  reducers: {
    setIsLoggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setTokenPromise(state, payload: any) {
      state.tokenPromise = payload;
    },
    setEmail(state, payload: string) {
      state.email = payload;
    },
    setUser(state, payload: IUser | null) {
      state.user = payload;
    },
    setHashData(state, payload: string) {
      state.hashData = payload;
    },
    setAttesationType(state, payload: 'text' | 'document') {
      state.attesationType = payload;
    },
  },
});
